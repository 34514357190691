import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import ButtonPanel from '../common/buttonPanel';
import Confirmation from '../common/confirmation';
import Icon from '../common/icon';
import Input from '../common/input';
import Page from '../common/page';

import InventoryModal from './inventoryModal';

function Inventory() {
  const params = useParams();
  const inventoryId = params.inventoryId;

  const locationId = localStorage.getItem('locationId');

  const [inventory, setInventory] = useState({ purchases: [] });

  const [errorMessage, setErrorMessage] = useState('');

  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [showWasteModal, setShowWasteModal] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [showDeletePurchaseModal, setShowDeletePurchaseModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getInventory = async () => {
      try {
        const response = await http.get(
          `/inventory/getById?inventory_id=${inventoryId}&location_id=${locationId}`
        );
        setInventory(response.data);
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    };
    getInventory();
  }, [inventoryId, locationId]);

  const handleDelete = async id => {
    await http.delete(`/inventory/deletePurchase?inventory_purchase_id=${id}`);
    navigate(0);
  };

  return (
    <Page selectedTab="inventory">
      <div className="p-4 background-gray box-shadow d-flex flex-column">
        {errorMessage && <p className="error">{errorMessage}</p>}
        <div className="d-flex justify-content-between">
          <h1 className="pb-4">{inventory.name}</h1>
          <button onClick={() => setShowInventoryModal(true)}>
            <Icon name="edit" className="blue m-0" />
          </button>
        </div>

        <div className="mb-5">
          <div className="mb-3 d-flex align-items-center">
            <h3>Balance:</h3>
            <p className={`ms-4 sura font-14 ${inventory.lowBalance ? 'red' : ''}`}>
              {inventory.balance} {inventory.unit_of_measure}
            </p>
          </div>

          <div className="mb-3 d-flex align-items-center">
            <h3>Order Point:</h3>
            <p className="ms-4 font-14">{inventory.order_point}</p>
          </div>

          <div className="mb-3 d-flex align-items-center">
            <h3>Notice Before Expiration:</h3>
            <p className="ms-4 font-14">{inventory.notice_before_expiration || 0} days</p>
          </div>

          <div className="d-flex align-items-center">
            <h3>Notes:</h3>
            <p className="ms-4 font-14">{inventory.notes}</p>
          </div>
        </div>

        <h2 className="mb-3">Purchases</h2>
        <div className="d-flex align-self-end">
          <button className="mb-3 me-3 btn-text-error" onClick={() => setShowWasteModal(true)}>
            <Icon name="delete" />
            Waste
          </button>
          <button
            className="mb-3 me-3 btn-text-primary align-self-end"
            onClick={() => {
              setSelectedPurchase(null);
              setShowPurchaseModal(true);
            }}
          >
            <Icon name="add" />
            New
          </button>
        </div>

        <table>
          <thead>
            <tr className="border-bottom">
              <th>Date</th>
              <th>Quantity</th>
              <th>Balance</th>
              <th>Expiration Date</th>
              <th>Lot Number</th>
              <th>Manufacturer</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {inventory.purchases.map((t, i) => {
              return (
                <tr key={i}>
                  <td>{moment(t.date).format('MM-DD-YYYY')}</td>
                  <td>{t.quantity}</td>
                  <td>{t.balance}</td>
                  <td className={`${Number(t.balance) > 0 && t.isExpiring ? 'red' : ''}`}>
                    {t.expiration_date
                      ? moment.tz(t.expiration_date, 'America/Denver').format('MM-DD-YYYY')
                      : ''}
                  </td>
                  <td>{t.lot_number}</td>
                  <td>{t.manufacturer}</td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <button
                        onClick={() => {
                          setSelectedPurchase(t);
                          setShowPurchaseModal(true);
                        }}
                      >
                        <Icon name="edit" className="blue m-0" />
                      </button>
                      <button
                        className="ms-2"
                        onClick={() => {
                          setSelectedPurchase(t);
                          setShowDeletePurchaseModal(true);
                        }}
                      >
                        <Icon name="fa fa-trash" className="red" tooltipText="Delete" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showInventoryModal && (
        <InventoryModal
          inventory={inventory}
          locationId={locationId}
          handleClose={() => setShowInventoryModal(false)}
        />
      )}

      {showWasteModal && (
        <WasteModal
          inventoryId={inventory.id}
          locationId={locationId}
          handleClose={() => setShowWasteModal(false)}
        />
      )}

      {showPurchaseModal && (
        <PurchaseModal
          purchase={selectedPurchase}
          inventoryId={inventory.id}
          locationId={locationId}
          handleClose={() => setShowPurchaseModal(false)}
        />
      )}

      {showDeletePurchaseModal && (
        <Confirmation
          message="Are you sure you would like to delete this Purchase? This action cannot be undone."
          handleConfirm={() => handleDelete(selectedPurchase.id)}
          handleClose={() => setShowDeletePurchaseModal(false)}
        />
      )}
    </Page>
  );
}

function WasteModal({ inventoryId, locationId, handleClose }) {
  const [amount, setAmount] = useState(0);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await http.post(`/inventory/waste`, { inventoryId, locationId, amount });
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>

        <Input name="amount" type="number" value={amount} label="Amount" onChange={setAmount} />

        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={!amount}
        />
      </form>
    </Modal>
  );
}

function PurchaseModal({ purchase, inventoryId, locationId, handleClose }) {
  const isUpdate = purchase && Boolean(purchase.id);

  const [date, setDate] = useState(
    isUpdate
      ? moment.tz(purchase.date, 'America/Denver').format('YYYY-MM-DD')
      : moment().tz('America/Denver').format('YYYY-MM-DD')
  );
  const [quantity, setQuantity] = useState(isUpdate ? purchase.quantity : 0);
  const [balance, setBalance] = useState(isUpdate ? purchase.balance : 0);
  const [expirationDate, setExpirationDate] = useState(
    isUpdate && purchase.expiration_date
      ? moment.tz(purchase.expiration_date, 'America/Denver').format('YYYY-MM-DD')
      : ''
  );
  const [lotNumber, setLotNumber] = useState(isUpdate ? purchase.lot_number || '' : '');
  const [manufacturer, setManufacturer] = useState(isUpdate ? purchase.manufacturer || '' : '');

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const params = {
      date: moment.tz(date, 'America/Denver'),
      quantity,
      expirationDate: moment.tz(expirationDate, 'America/Denver') || null,
      lotNumber: lotNumber || null,
      manufacturer: manufacturer || null,
      locationId,
    };

    try {
      if (isUpdate) {
        await http.post(`/inventory/updatePurchase`, { id: purchase.id, balance, ...params });
      } else {
        await http.post(`/inventory/createPurchase`, { inventoryId, ...params });
      }
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }

    navigate(0);
  };

  return (
    <Modal show={true} onHide={handleClose} centered>
      <form>
        <p className="error">{errorMessage}</p>

        <Input name="date" type="date" value={date} label="Date" onChange={setDate} />
        <Input
          name="quantity"
          type="number"
          value={quantity}
          label="Quantity"
          onChange={setQuantity}
        />
        {isUpdate && (
          <Input
            name="balance"
            type="number"
            value={balance}
            label="Balance"
            onChange={setBalance}
          />
        )}
        <Input
          name="expirationDate"
          type="date"
          value={expirationDate}
          label="Expiration Date"
          onChange={setExpirationDate}
        />
        <Input name="lotNumber" value={lotNumber} label="Lot Number" onChange={setLotNumber} />
        <Input
          name="manufacturer"
          value={manufacturer}
          label="Manufacturer"
          onChange={setManufacturer}
        />

        <ButtonPanel
          primaryButtonText="Save"
          handleCancel={handleClose}
          handleSubmit={handleSubmit}
          disabled={!date || !quantity || (isUpdate && !balance)}
        />
      </form>
    </Modal>
  );
}

export default Inventory;

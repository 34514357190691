import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Collapse from 'react-bootstrap/Collapse';
import moment from 'moment-timezone';

import http from '../../services/httpService';

import Icon from '../common/icon';
import Input from '../common/input';
import InputCheckbox from '../common/inputCheckbox';
import InputCustom from '../common/inputCustom';
import InputEitherOr from '../common/inputEitherOr';
import InputSelect from '../common/inputSelect';
import InputTextarea from '../common/inputTextarea';
import Page from '../common/page';

import EstimatorModal from './estimatorModal';

import { formatCurrency, formatDate } from '../../util';

import Constants from '../../constants';
import EstimateConstants from '../../estimateConstants';

import '../../styles/components/estimate.scss';

function EstimateGenerator({ isDuplicate }) {
  const location = useLocation();
  const params = useParams();
  const surgery = location.state && location.state.surgery ? location.state.surgery : null;

  const [estimateId, setEstimateId] = useState(params.estimateId);
  const [statusId, setStatusId] = useState(0);
  const [estimate, setEstimate] = useState();

  const [petId, setPetId] = useState(null);
  const [pet, setPet] = useState(null);

  const [estimateConfig, setEstimateConfig] = useState(
    estimateId || surgery
      ? null
      : {
          type: EstimateConstants.HP_COMPARISON,
          title: 'Estimate',
          ownerName: '',
          patientName: '',
          expirationDate: moment().tz('America/Denver').add(30, 'days').format('YYYY-MM-DD'),
          speciesId: Constants.CANINE,
          items: [EstimateConstants.OFFICE_VISIT],
          includeHpPaymentDetails: true,
          paymentInterval: Constants.MONTHLY,
          displayVersions: [true, false, false],
          paymentPlanEligible: true,
        }
  );

  const [quickAddOpen, setQuickAddOpen] = useState(false);
  const [specialTypeId, setSpecialTypeId] = useState(0);
  const [disablePaymentPlans, setDisablePaymentPlans] = useState(false);

  const [selectedServiceIndex, setSelectedServiceIndex] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getEstimate = async () => {
      const response = await http.get(`/estimate/getById?estimate_id=${estimateId}`);
      setEstimateConfig(JSON.parse(response.data.info));
      setPetId(response.data.pet_id);

      if (isDuplicate) {
        setEstimateId(null);
      } else {
        setStatusId(response.data.status_id);
      }
    };
    if (estimateId) {
      getEstimate();
    }
  }, [estimateId, isDuplicate]);

  useEffect(() => {
    if (location.state && location.state.pet) {
      handlePetChange(location.state.pet);
    }
  }, [location]);

  useEffect(() => {
    const getEstimateConfig = async () => {
      const response = await http.get(
        `/estimate/getSurgeryEstimateDraft?pet_id=${surgery.pet_id}&surgery_type_id=${surgery.surgeryTypeId}&blocks=${surgery.numBlocks}&blocks_secondary=${surgery.numBlocksSecondary}`
      );
      setEstimateConfig(response.data);
      setPetId(surgery.pet_id);
    };
    if (surgery) {
      getEstimateConfig();
    }
  }, [surgery]);

  useEffect(() => {
    const getEstimate = async () => {
      const response = await http.post('/estimate/generate', estimateConfig, {
        responseType: 'blob',
      });

      const file = new Blob([response.data], { type: 'application/pdf' });
      const estimate = URL.createObjectURL(file);
      setEstimate(estimate);
    };
    if (estimateConfig) {
      getEstimate();
    }
  }, [estimateConfig]);

  useEffect(() => {
    const getPet = async () => {
      const { data: pet } = await http.get(`/pet/getById?pet_id=${petId}`);
      setPet(pet);

      if (pet.no_payment_plans === 'Y') {
        setDisablePaymentPlans(true);
        setEstimateConfig({
          ...estimateConfig,
          paymentInterval: Constants.ANNUAL,
          paymentPlanEligible: false,
        });
      }
    };
    if (petId) {
      getPet();
    }
  }, [petId]);

  const handlePetChange = async pet => {
    try {
      const { data: displayVersions } = await http.get(`/pet/getDisplayVersions?pet_id=${pet.id}`);

      setPetId(pet.id);

      const updatedEstimateConfig = { ...estimateConfig };
      updatedEstimateConfig.ownerName = `${pet.first_name} ${pet.last_name}`;
      updatedEstimateConfig.patientName = pet.name;
      updatedEstimateConfig.petId = pet.id;
      updatedEstimateConfig.speciesId = Number(pet.species_id);
      updatedEstimateConfig.includeHpPaymentDetails = false;
      updatedEstimateConfig.displayVersions = displayVersions;
      setEstimateConfig(updatedEstimateConfig);
    } catch (error) {
      setErrorMessage(error.response.data.message);
    }
  };

  const handleConfigChange = (key, value) => {
    const updatedConfig = { ...estimateConfig };
    updatedConfig[key] = value;
    setEstimateConfig(updatedConfig);
  };

  const handleDisplayVersionChange = (index, value) => {
    const updatedConfig = { ...estimateConfig };
    updatedConfig.displayVersions[index] = value;
    setEstimateConfig(updatedConfig);
  };

  const handleDisplayVersionChangeTreatmentPlan = index => {
    const updatedConfig = { ...estimateConfig };
    const updatedDisplayVersions = [false, false, false];
    updatedDisplayVersions[index] = true;
    updatedConfig.displayVersions = updatedDisplayVersions;
    setEstimateConfig(updatedConfig);
  };

  const handleQuickAdd = (items, specialTypeId, ineligibleForPaymentPlan) => {
    const updatedEstimateConfig = { ...estimateConfig };
    updatedEstimateConfig.items = [...estimateConfig.items, ...items];

    if (specialTypeId) {
      updatedEstimateConfig.items.shift(); //remove Office Visit

      if (specialTypeId !== 'dr_smith') {
        updatedEstimateConfig.title = EstimateConstants.SURGERY_TYPE_MAP.find(
          t => t.value === specialTypeId
        ).title;
        setSpecialTypeId(specialTypeId);
      }
    }

    if (ineligibleForPaymentPlan) {
      updatedEstimateConfig.paymentPlanEligible = false;
      setDisablePaymentPlans(true);
    }

    setEstimateConfig(updatedEstimateConfig);
  };

  const handleSave = async () => {
    setErrorMessage('');
    if (estimateId) {
      await http.put('/estimate/update', { estimateId, info: estimateConfig });
    } else {
      const params = { petId, info: estimateConfig };
      if (surgery) {
        params.appointmentId = surgery.id;
      }
      await http.post('/estimate/create', params);
    }
    navigate(
      location.state && location.state.redirectPath ? location.state.redirectPath : '/estimates'
    );
  };

  if (!estimateConfig) return <i className="fa fa-circle-notch fa-spin" />;

  return (
    <Page selectedTab="estimate-generator">
      <div className="p-3 background-gray box-shadow">
        <div className="d-flex justify-content-between mt-3">
          <h1 className="pb-4">Estimate Tool</h1>
          <button
            className="btn-filled-primary me-5"
            onClick={handleSave}
            disabled={!petId || (statusId && statusId !== EstimateConstants.DRAFT)}
          >
            Save
          </button>
        </div>
        <InputCustom
          selectedId={petId}
          retrievalEndpoint="/pet/getById?pet_id="
          searchEndpoint="/pet/search?"
          displayNameField="displayName"
          label="Pet"
          handleChange={handlePetChange}
          handleClear={() => {
            setPetId(null);
            setPet(null);
            const updatedEstimateConfig = { ...estimateConfig };
            updatedEstimateConfig.patientName = '';
            updatedEstimateConfig.petId = '';
            updatedEstimateConfig.ownerName = '';
            setEstimateConfig(updatedEstimateConfig);
          }}
          disableClear={estimateId || surgery}
        />
        {!petId && (
          <InputEitherOr
            name="speciesId"
            label="Species"
            value={estimateConfig.speciesId}
            option1={{ name: 'Canine', value: Constants.CANINE }}
            option2={{ name: 'Feline', value: Constants.FELINE }}
            onChange={({ target }) => handleConfigChange('speciesId', Number(target.value))}
          />
        )}
        <Input
          name="ownerName"
          value={estimateConfig.ownerName}
          label="Owner Name"
          onChange={value => handleConfigChange('ownerName', value)}
        />
        <h2 className="my-3">General</h2>
        <div className="ms-4">
          <p className="error">{errorMessage}</p>
          <InputEitherOr
            name="type"
            label="Type"
            value={estimateConfig.type}
            option1={{ name: 'HP Comparison', value: EstimateConstants.HP_COMPARISON }}
            option2={{ name: 'Tx Plan', value: EstimateConstants.TREATMENT_PLAN }}
            onChange={({ target }) => {
              const updatedConfig = { ...estimateConfig };
              const updatedDisplayVersions = [false, false, false];
              updatedDisplayVersions[estimateConfig.displayVersions.findIndex(t => t)] = true;
              updatedConfig.displayVersions = updatedDisplayVersions;
              updatedConfig.type = Number(target.value);
              updatedConfig.includeHpPaymentDetails =
                updatedConfig.type === EstimateConstants.HP_COMPARISON;
              setEstimateConfig(updatedConfig);
            }}
          />
          <InputTextarea
            name="title"
            value={estimateConfig.title}
            label="Title"
            rows="2"
            onChange={value => handleConfigChange('title', value)}
          />
          <Input
            type="date"
            name="expirationDate"
            label="Expiration Date"
            value={estimateConfig.expirationDate}
            onChange={value => handleConfigChange('expirationDate', value)}
          />
        </div>
        <h2 className="my-3">Estimate Items</h2>
        <div className="ms-4 mb-4">
          {estimateConfig.items.length > 0 && (
            <div className=" p-2">
              <table>
                <thead>
                  <tr className="border-bottom">
                    <th>Description</th>
                    <th>Price</th>
                    <th></th>
                    <th>Pricing Category</th>
                    <th>Quantity</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {estimateConfig.items.map((t, i) => (
                    <tr key={i}>
                      <td>{t.description}</td>
                      <td>{formatCurrency(t.lowerRange * 100)}</td>
                      <td>{t.upperRange ? formatCurrency(t.upperRange * 100) : ''}</td>
                      <td>
                        {
                          EstimateConstants.PRICING_CATGORY_MAP.find(
                            s => Number(t.pricingCategory) === s.value
                          ).name
                        }
                      </td>
                      <td>{t.quantity || 1}</td>
                      <td>
                        <div className="d-flex">
                          {!['Office Visit', 'Spay Package', 'Neuter Package'].includes(
                            t.description
                          ) && (
                            <button
                              onClick={() => {
                                setSelectedServiceIndex(i);
                                setShowEditModal(true);
                              }}
                            >
                              <Icon name="edit" className="blue m-0" />
                            </button>
                          )}
                          <button
                            className="ms-2"
                            onClick={() => {
                              const updatedEstimateConfig = { ...estimateConfig };
                              updatedEstimateConfig.items = estimateConfig.items.filter(
                                (t, j) => j !== i
                              );
                              setEstimateConfig(updatedEstimateConfig);
                            }}
                          >
                            <Icon name="delete" className="error m-0" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <button className="mb-2 btn-text-primary" onClick={() => setShowAddModal(true)}>
            <Icon name="add" />
            Add
          </button>
          <div
            className="flex-row-aligned py-3 pointer"
            onClick={() => setQuickAddOpen(!quickAddOpen)}
          >
            {quickAddOpen && <Icon name="less" />}
            {!quickAddOpen && <Icon name="more" />}
            <h3 className="sura">Quick Add</h3>
          </div>
          <Collapse in={quickAddOpen}>
            <div className="quick-add ms-4 mb-4 p-3 border rounded">
              <div>
                <p className="mt-2">Dental</p>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(0.5), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (0.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(1), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (1 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(1.5), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (1.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(2), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (2 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(2.5), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (2.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(3), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (3 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(3.5), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (3.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL(4), 1)}
                  disabled={specialTypeId === 1}
                >
                  Dental (4 blk)
                </button>
                <p className="mt-3">Ad-Hoc Dental</p>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(1))}
                  disabled={specialTypeId === 1}
                >
                  &lt; 5 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(2))}
                  disabled={specialTypeId === 1}
                >
                  5-10 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(3))}
                  disabled={specialTypeId === 1}
                >
                  10-15 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(4))}
                  disabled={specialTypeId === 1}
                >
                  15-20 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(5))}
                  disabled={specialTypeId === 1}
                >
                  20-25 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(6))}
                  disabled={specialTypeId === 1}
                >
                  0.5 blk
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(12))}
                  disabled={specialTypeId === 1}
                >
                  1 blk
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(18))}
                  disabled={specialTypeId === 1}
                >
                  1.5 blk
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.DENTAL_AD_HOC(24))}
                  disabled={specialTypeId === 1}
                >
                  2 blk
                </button>
              </div>
              <div>
                <p className="mt-2">GA</p>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(0.5), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (0.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(1), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (1 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(1.5), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (1.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(2), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (2 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(2.5), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (2.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(3), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (3 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(3.5), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (3.5 blk)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA(4), 4)}
                  disabled={specialTypeId === 4}
                >
                  GA (4 blk)
                </button>
                <p className="mt-3">Ad-Hoc GA</p>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(1))}
                  disabled={specialTypeId === 4}
                >
                  &lt; 5 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(2))}
                  disabled={specialTypeId === 4}
                >
                  5-10 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(3))}
                  disabled={specialTypeId === 4}
                >
                  10-15 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(4))}
                  disabled={specialTypeId === 4}
                >
                  15-20 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(5))}
                  disabled={specialTypeId === 4}
                >
                  20-25 min
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(6))}
                  disabled={specialTypeId === 4}
                >
                  0.5 blk
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(12))}
                  disabled={specialTypeId === 4}
                >
                  1 blk
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(18))}
                  disabled={specialTypeId === 4}
                >
                  1.5 blk
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.GENERAL_ANESTHESIA_AD_HOC(24))}
                  disabled={specialTypeId === 4}
                >
                  2 blk
                </button>
              </div>
              <div>
                <p className="mt-2">Spay/Neuter</p>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.SPAY_NEUTER('Spay'), 2)}
                  disabled={specialTypeId}
                >
                  Spay
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.SPAY_NEUTER('Neuter'), 3)}
                  disabled={specialTypeId}
                >
                  Neuter
                </button>
                <p className="mt-3">Add Ons</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.GASTROPEXY)}>
                  Gastropexy
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.SCROTAL_ABLATION)}>
                  Scrotal Ablation
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.DEWCLAW_1)}>
                  Dewclaw (Grade 1)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.DEWCLAW_2)}>
                  Dewclaw (Grade 2)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.PYOMETRA)}>Pyometra</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.HERNIA)}>
                  Umbilical Hernia
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.BIOPSY)}>
                  Idexx Biopsy (2711)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.COMPLEX_BIOPSY)}>
                  Idexx Biopsy (7217)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.E_COLLAR)}>E-Collar</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.MICROCHIP)}>
                  Microchip
                </button>
                <p className="mt-3">Elective Mass Removal</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.ELECTIVE_EYELID_MR)}>
                  Eyelid MR (Cautery)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.ELECTIVE_LUMP_1)}>
                  Lump. (Grade 1)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.ELECTIVE_LUMP_2)}>
                  Lump. (Grade 2)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.ELECTIVE_LUMP_3)}>
                  Lump. (Grade 3)
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.ELECTIVE_LUMP_4)}>
                  Lump. (Grade 4)
                </button>
              </div>
              <div>
                <p className="mt-2">PC</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.NAIL_TRIM)}>
                  Nail Trim
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.ANAL_SAC_EXPRESSION)}>
                  AGE
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.FECAL)}>Fecal</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.HWT)}>HW Test</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.TRIPLE)}>
                  Feline Triple
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.PYRANTEL)}>Pyrantel</button>
                <p className="mt-4">Vaccines</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.BORD)}>Bordetella</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.LEPTO)}>Lepto</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.DISTEMPER)}>DAPP</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.COMBO)}>DAPP/L</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.RABIES)}>Rabies</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.FVRCP)}>FVRCP</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.LEUKEMIA)}>Leukemia</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.INFLUENZA)}>
                  Influenza
                </button>
              </div>
              <div>
                <p className="mt-2">Diagnostics</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.ULTRASOUND, null, true)}>
                  Ultrasound
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.URINALYSIS)}>
                  UA Bundle
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.CHEM_10)}>Chem 10</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.CHEM_15)}>Chem 15</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.CHEM_15_LYTES)}>
                  Chem 15 w/ Lytes
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.CBC)}>CBC</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.SDMA)}>SDMA</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.SEDATION)}>Sedation</button>
                <p className="mt-4">Radiographs</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.RAD_1)}>Rads (1)</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.RAD_2)}>Rads (2)</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.RAD_3)}>Rads (3)</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.RAD_4)}>Rads (4)</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.RAD_5)}>Rads (5)</button>
                <button onClick={() => handleQuickAdd(EstimateConstants.RAD_6)}>Rads (6)</button>
              </div>
              <div>
                <p className="mt-2">End of Life</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.EUTHANASIA, null, true)}>
                  Euthanasia
                </button>
                <button
                  onClick={() =>
                    handleQuickAdd(EstimateConstants.CREMATION_COMM_UNDER_5, null, true)
                  }
                >
                  Communal Cremation (&lt; 5)
                </button>
                <button
                  onClick={() =>
                    handleQuickAdd(EstimateConstants.CREMATION_COMM_OVER_5, null, true)
                  }
                >
                  Communal Cremation (&gt; 5)
                </button>
                <button
                  onClick={() =>
                    handleQuickAdd(EstimateConstants.CREMATION_PRI_UNDER_3, null, true)
                  }
                >
                  Private Cremation (&lt; 3)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.CREMATION_PRI_OVER_3, null, true)}
                >
                  Private Cremation (&gt; 3)
                </button>
                <p className="mt-4">Misc.</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.FOREIGN_BODY_SX)}>
                  Foreign Body Sx
                </button>
                <button onClick={() => handleQuickAdd(EstimateConstants.BROKEN_TOENAIL)}>
                  Broken Toenail
                </button>
                <p className="mt-4">Dr. Smith Sx</p>
                <button onClick={() => handleQuickAdd(EstimateConstants.TPLO_90, 'dr_smith', true)}>
                  TPLO &lt; 90
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.TPLO_90_120, 'dr_smith', true)}
                >
                  TPLO 90-120
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.TPLO_120_150, 'dr_smith', true)}
                >
                  TPLO 120-150
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.TPLO_150, 'dr_smith', true)}
                >
                  TPLO &gt; 150
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.TPLO_90_BI, 'dr_smith', true)}
                >
                  TPLO &lt; 90 (Bilateral)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.TPLO_90_120_BI, 'dr_smith', true)}
                >
                  TPLO 90-120 (Bilateral)
                </button>
                <button
                  onClick={() =>
                    handleQuickAdd(EstimateConstants.TPLO_120_150_BI, 'dr_smith', true)
                  }
                >
                  TPLO 120-150 (Bilateral)
                </button>
                <button
                  onClick={() => handleQuickAdd(EstimateConstants.TPLO_150_BI, 'dr_smith', true)}
                >
                  TPLO &gt; 150 (Bilateral)
                </button>
              </div>
            </div>
          </Collapse>
          {petId && (
            <InputCheckbox
              className="mt-3 mb-5"
              name="paymentPlanEligible"
              label="Show Payment Plan Info"
              checked={estimateConfig.paymentPlanEligible}
              onChange={value => handleConfigChange('paymentPlanEligible', value)}
              disabled={disablePaymentPlans}
            />
          )}
        </div>
        {(!pet || !pet.waiting_period_end_date) && (
          <React.Fragment>
            <h2 className="mb-3">Health Plan Selection</h2>
            <div className="ms-4 mb-4">
              {Number(estimateConfig.type) === EstimateConstants.TREATMENT_PLAN && (
                <InputSelect
                  name="healthPlan"
                  value={estimateConfig.displayVersions.findIndex(t => t)}
                  optionConfig={EstimateConstants.HP_OPTIONS_MAP}
                  onChange={handleDisplayVersionChangeTreatmentPlan}
                />
              )}
              {Number(estimateConfig.type) === EstimateConstants.HP_COMPARISON && (
                <React.Fragment>
                  <InputCheckbox
                    className="mt-3"
                    name="noPlan"
                    label="No Health Plan"
                    checked={estimateConfig.displayVersions[0]}
                    onChange={value => handleDisplayVersionChange(0, value)}
                  />
                  <InputCheckbox
                    className="mt-3"
                    name="fifty"
                    label="50%"
                    checked={estimateConfig.displayVersions[1]}
                    onChange={value => handleDisplayVersionChange(1, value)}
                  />
                  <InputCheckbox
                    className="mt-3"
                    name="ninety"
                    label="90%"
                    checked={estimateConfig.displayVersions[2]}
                    onChange={value => handleDisplayVersionChange(2, value)}
                  />
                </React.Fragment>
              )}
            </div>
            {Number(estimateConfig.type) === EstimateConstants.HP_COMPARISON && (
              <React.Fragment>
                <h2 className="mb-3">Health Plan Payment Details</h2>
                <div className="ms-4 mb-4">
                  <InputCheckbox
                    className="my-2"
                    name="includeHpPaymentDetails"
                    label="Include Section"
                    checked={estimateConfig.includeHpPaymentDetails}
                    onChange={value => handleConfigChange('includeHpPaymentDetails', value)}
                  />
                  {estimateConfig.includeHpPaymentDetails && (
                    <InputEitherOr
                      name="paymentInterval"
                      label="Payment Option"
                      value={estimateConfig.paymentInterval}
                      option1={{ name: 'Monthly', value: Constants.MONTHLY }}
                      option2={{ name: 'Annual', value: Constants.ANNUAL }}
                      onChange={({ target }) =>
                        handleConfigChange('paymentInterval', Number(target.value))
                      }
                      disabled={pet && pet.no_payment_plans === 'Y'}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {pet && pet.no_payment_plans === 'Y' && (
          <p className="ms-1 mb-3 sura font-16 error">*Client is not eligible for payment plans</p>
        )}
        {pet && pet.waiting_period_end_date && (
          <p className="ms-1 mb-3 sura font-16 error">
            *Pet is in Waiting Period and is unable to sign up for a Health Plan until{' '}
            {formatDate(pet.waiting_period_end_date)}
          </p>
        )}
        <embed
          src={estimate}
          className="mt-4"
          type="application/pdf"
          width="100%"
          height="1150px"
        />
      </div>
      {showAddModal && (
        <EstimatorModal
          handleClose={() => setShowAddModal(false)}
          handleSubmit={item => {
            const updatedEstimateConfig = { ...estimateConfig };
            updatedEstimateConfig.items.push(item);
            setEstimateConfig(updatedEstimateConfig);
            setShowAddModal(false);
          }}
        />
      )}
      {showEditModal && (
        <EstimatorModal
          selectedService={estimateConfig.items[selectedServiceIndex]}
          handleClose={() => setShowEditModal(false)}
          handleSubmit={item => {
            const updatedEstimateConfig = { ...estimateConfig };
            updatedEstimateConfig.items[selectedServiceIndex] = item;
            setEstimateConfig(updatedEstimateConfig);
            setShowEditModal(false);
          }}
        />
      )}
    </Page>
  );
}

export default EstimateGenerator;
